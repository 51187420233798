	/*
  	Flaticon icon font: Flaticon
  	Creation date: 29/01/2021 10:05
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
       url("./Flaticon.woff2") format("woff2"),
       url("./Flaticon.woff") format("woff"),
       url("./Flaticon.ttf") format("truetype"),
       url("./Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
	font-family: Flaticon;
	font-style: normal;
}

.flaticon-website:before { content: "\f100"; }
.flaticon-line-graph:before { content: "\f101"; }
.flaticon-line-chart:before { content: "\f102"; }
.flaticon-dollar:before { content: "\f103"; }
.flaticon-help:before { content: "\f104"; }
.flaticon-pie-charts:before { content: "\f105"; }
.flaticon-idea:before { content: "\f106"; }
.flaticon-office:before { content: "\f107"; }
.flaticon-boost:before { content: "\f108"; }
.flaticon-boost-1:before { content: "\f109"; }
.flaticon-location:before { content: "\f10a"; }
.flaticon-email:before { content: "\f10b"; }
.flaticon-smile:before { content: "\f10c"; }
.flaticon-confetti:before { content: "\f10d"; }
.flaticon-laptop:before { content: "\f10e"; }
.flaticon-paper-plane:before { content: "\f10f"; }
.flaticon-air-mail:before { content: "\f110"; }
.flaticon-startup:before { content: "\f111"; }
.flaticon-rocket:before { content: "\f112"; }
.flaticon-promotion:before { content: "\f113"; }
.flaticon-chart:before { content: "\f114"; }
.flaticon-vector:before { content: "\f115"; }
.flaticon-coding:before { content: "\f116"; }
.flaticon-play:before { content: "\f117"; }
.flaticon-chat:before { content: "\f118"; }
.flaticon-bullhorn:before { content: "\f119"; }
.flaticon-sketch:before { content: "\f11a"; }
.flaticon-clock:before { content: "\f11b"; }
.flaticon-user:before { content: "\f11c"; }
.flaticon-speech-bubble:before { content: "\f11d"; }
.flaticon-share:before { content: "\f11e"; }
.flaticon-left-quote:before { content: "\f11f"; }
.flaticon-check:before { content: "\f120"; }
.flaticon-quotation:before { content: "\f121"; }
.flaticon-phone:before { content: "\f119"; }